@import '../../static/variables';
@import '../../static/mixins';

.Loader {
  @include forced_center;
  display: flex;
  justify-content: space-between;
  width: 40px;
  height: 45px;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;

  > div {
    background: color(black);
    width: 12%;
    height: 100%;
    display: inline-block;
    
    animation: sk-stretchdelay 1.2s infinite ease-in-out;

    &:nth-of-type(2) {
      animation-delay: -1.1s;
    }
  
    &:nth-of-type(3) {
      animation-delay: -1.0s;
    }
  
    &:nth-of-type(4) {
      animation-delay: -0.9s;
    }
  
    &:last-of-type {
      animation-delay: -0.8s;
    }
  }
  
  @keyframes sk-stretchdelay {
    0%, 40%, 100% { 
      transform: scaleY(0.4);
    }  20% { 
      transform: scaleY(1.0);
    }
  }

  &.hidden {
    opacity: 0;
  }
}


.DarkMode .Loader {
  > div {
    background: color(white);
  }
}