@mixin fonts {

  @import url("https://use.typekit.net/unn7jkm.css");
  @import url('https://fonts.googleapis.com/css?family=Lora:400,400i,700,700i&display=swap');

  @font-face {
    font-family: 'Lavanderia';
    src: url('./fonts/LavanderiaRegular.otf');
    src: url('./fonts/LavanderiaRegular.otf') format('opentype');
  }

  @font-face {
    font-family: 'Lavanderia';
    font-weight: lighter;
    src: url('./fonts/LavanderiaDelicate.otf');
    src: url('./fonts/LavanderiaDelicate.otf') format('opentype');
  }

  @font-face {
    font-family: 'Lavanderia';
    font-weight: bold;
    src: url('./fonts/LavanderiaSturdy.otf');
    src: url('./fonts/LavanderiaSturdy.otf') format('opentype');
  }

  @font-face {
    font-family: 'Futura';
    src: url('./fonts/Futura-Medium.ttf');
    src: url('./fonts/Futura-Medium.ttf') format('ttf');
  }

}

@mixin Bebas($weight: 400) { // 300 - Book | 400 - Regular | 700 - Bold
  font-family: bebas-neue-by-fontfabric, sans-serif;
  font-weight: $weight;
  font-style: normal;
}