@import '../static/variables';
@import '../static/mixins';

.App {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  background: radial-gradient(color(white), color(black, 0.1));
  color: color(darkgrey);

  h1, h2 {
    color: color(black);
  }

  .pages {
    padding: ($spacing * 2) 0 ($spacing * 4);

    @include media-md {
      padding: ($spacing * 2) 0;
    }
  }
}

.App:before {
  @include pseudo;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(color(black, 0.85), color(black, 0.9));
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.App.DarkMode {
  color: color(lightgrey);

  h1, h2 {
    color: color(white);
  }
}

.App.DarkMode:before {
  opacity: 1;
}

.App:not(.DarkMode) p {
  a,
  a:visited {
    text-shadow: 0px 0px 1px color(black, 0.5);
  }
}