@import '../../../../static/variables';
@import '../../../../static/mixins';

.Content {
  height: 100%;

  .row {
    height: 100%;
    align-items: center;
    justify-content: center;
  }

  .detail {
    margin-bottom: $spacing;

    span {
      font-size: 0.8em;
    }
  }

  p a {
    font-size: 0.8em;
  }
}

.Content .row > div {
  transition: all 0.75s ease-in-out;
  opacity: 0;
}

section.active .Content .row > div {
  opacity: 1;
}

section:not(.active) .Content .row > div:first-of-type {
  transform: translateY(30vh);
}

.is-mobile {
  .Content .row > div {
    opacity: 1;
  }
  
  section:not(.active) .Content .row > div:first-of-type {
    transform: translateY(0);
  }
}