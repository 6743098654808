@import '../../../../static/variables';
@import '../../../../static/mixins';

.Slide {
  $ScaleTransition: transform 0.35s ease-in-out;

  cursor: pointer;

  transform: scale(0.8);
  transition: $ScaleTransition;

  overflow: hidden;

  &.current {
    transform: scale(1);
  }

  .work {
    width: 100%;
    height: calc(80vh - #{$spacing * 6});

    @include media-md {
      height: 65vh;
    }
  
    transform: scale(calc(1 / 0.8));
    transition: $ScaleTransition, height 0.2s ease-in-out;
  }
  
  &.current .work {
    transform: scale(1);
  }

  &.current:not(.transitioning) .work {
    background: color(black);
  }

  .thumbnail {
    width: 100%;
    height: 100%;
  
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    transition: opacity 0.3s ease-in-out;
    opacity: 1;
  }
  
  &.current:hover .thumbnail {
    transition-delay: 0.1s;
    opacity: 0.8;
  }
}

.Slide.to-project .work {
  height: $project-thumbnail-height;

  .thumbnail { opacity: 1 }
}

.Slide .set-current-layer {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.Slide.current .set-current-layer {
  pointer-events: none;
}