@import '../../static/variables';
@import '../../static/mixins';

.About {
  overflow-x: hidden;

  .col:last-of-type {
    padding-top: 1.75em;
  }

  h1 {
    position: relative;
  }
  
  h2 {
    margin: ($spacing / 2) 0;
    text-align: right;
    overflow: hidden;

    span {
      position: relative;
      display: inline-block;

      &:before {
        @include pseudo;
        right: calc(100% + 15px);
        top: calc(50% - 1px);
        
        width: 100vw;
        height: 1px;
        background: currentColor;
        opacity: 0.25;
      }
    }
  }

  ul {
    padding: 10px 0;

    li {
      display: list-item;
      list-style-type: disc;
      list-style-position: inside;
      margin-bottom: 8px;
    }
  }

  ul.skills {
    margin-bottom: $spacing;
    padding: 0;
    font-size: 0.9em;

    @include media-md {
      font-size: 0.85em;
    }
    
    li {
      display: block;
      margin-bottom: 10px;
      
      span {
        display: inline-block;
        width: 80px;
        padding-right: 5px;
        letter-spacing:.015em;

        @include media-md {
          width: 90px;
          text-align:right;
        }
      }
      
      div {
        position: relative;
        display: inline-block;
        padding: 0 5px;
        width: calc(100% - 80px - 2em);
        height: 5px;

        @include media-md {
          width: calc(100% - 90px - 2em);
          height: 7px;
        }
        
        > div {
          position: relative;
          height:100%;
          overflow:hidden;
          border-radius: 2px;
          z-index:1;
          @include media-md {
            border-radius: 4px;
          }

          @keyframes level-gauge {
            0% { transform: scaleX(0); }
            100% { transform: scaleX(1); }
          }
          
          > div {
            position:absolute;
            top:0;
            left:0;
            width: 100%;
            height:100%;

            transform: scaleX(0);
            transform-origin: 0% 50%;

            background:color(highlight);

            border-radius: 2px;

            @include media-md {
              border-radius: 4px;
            }

            &.animate-in {
              @include animation(level-gauge, 1s, ease-in-out, 0, 1, normal, forwards);
            }
          }
        }
      }
    }
  }
}
