@import '../../static/variables';
@import '../../static/mixins';
@import '../../static/fonts';

.Nav {
  position: relative;
  z-index: 20;
  border-bottom: 1px solid color(black);
  min-height: $spacing * 2;

  @include media-md {
    border-bottom: none;
  }
	
	.Hamburger {
    position: absolute;
    right: $spacing / 2;
    top: 50%;
    transform: translateY(-50%);

		color: color(darkgrey);
		
		@include media-md {
			display: none;
		}
	}

  ul {
    position: absolute;
    top: $spacing * 2;
    display: flex;
    width: 100%;
    justify-content: center;

    overflow: hidden;
		
		@include media-md {
      position: relative;
      top: 0;
      display: flex;
      width: auto;
		}
  }

  li {
    margin: 0 12px;

    a {
      @include Bebas();
      display: block;
      margin: ($spacing / 2) 0;
      font-size: 1.6em;
      letter-spacing: 0em;
      transition: color 0.15s ease-in-out, transform 0.3s ease-in-out;

      transform: translateY(calc(-100% - #{$spacing} / 2));

      @include media-md {
        margin: $spacing 0;
        transform: translateY(0%);
      }
    }

    &.current {
      color: color(black);
    }
    &:before {
      @include pseudo;
      left: 0;
      top: 0;
      width: 100%;
      height: 3px;
      background: color(black);
      transform-origin: 50% 0%;
      transform: scale(0);
      transition: transform 0.3s ease-in-out;
    }

    @include media-md {
      &.current:before {
        transform: scale(1);
      }
    }
  }

  .social-icons,
  .lightbulb {
    position: relative;
    display: inline-block;
    left: $spacing / 2;
    top: $spacing;
    transform: translateY(-50%);
    
    @include media-md {
      position: absolute;
      display: block;
      left: auto;
      transform: none;
    }

    a,
    button {
      display: inline-block;
      border: none;
      outline: none;
      cursor: pointer;
      margin: 0 12px 0 0;
      height: 25px;
      font-size: 1.75em;
      text-align:center;
    
      @include media-md {
        display: block;
        margin: 0 0 12px;
      }

      @include media-md-less {
        transform: translateX(0px) !important;
      }
    }

    span { display: none }
    
    i {
      transition: all 0.2s ease-in-out;
    }

    @include media-md-less {
      .HoverWobble {
        transform: none !important;
      }
    }
  }

  .social-icons {
    @include media-md {
      left: $spacing * 1.5;
    
      i.fa-envelope {
        font-size: 0.8em;
      }
    }
    @include media-lg {
      left: $spacing * 2;
    }
  }

  .lightbulb {
    @include media-md {
      right: $spacing * 1.5;
    }
    @include media-lg {
      right: $spacing * 2;
    }

    i {
      font-size: 1.15em;
    }
  }
}

.Nav.Toggled {
  ul li.current:before {
    transform: scale(1);
  }

  ul li a {
    transform: translateY(0%);
  }
}

.DarkMode .Nav {
  border-bottom-color: color(white);

  .Hamburger {
    color: color(lightgrey);
  }

  li.current {
    color: color(white);
  }

  li:before {
    background: color(white);
  }
}