@import '../../../../static/variables';
@import '../../../../static/mixins';

.Form {
  position: relative;
  overflow: hidden;

  label,
  input,
  textarea,
  button {
    opacity: 1;
    transition: all 0.25s ease-in-out;
  }

  label {
    display: block;
    opacity: 0.75;
    text-transform: capitalize;
    font-size: 0.8em;
  }

  .field {
    position: relative;

    &:after {
      @include pseudo;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 2px;
      background: color(black);

      transform-origin: 0% 50%;
      transition: transform 0.3s ease-in-out;
      transform: scaleX(0);
    }

    &.current {
      color: color(black);
    }


    &.current:after {
      transform: scaleX(1);
    }

    &.current label {
      opacity: 1 !important;
    }
  }

  $FieldHeight: $spacing + 5px * 2;

  input,
  textarea {
    display: block;
    width: 100%;
    height: $FieldHeight;

    padding: 5px 0;
    margin-bottom: 12px;

    border-bottom: 1px solid currentColor;

    border-radius: 0;
    outline: none;
    background: none;
  }

  textarea {
    resize: vertical;
    height: 250px;
    min-height: $FieldHeight;
    max-height: $FieldHeight;

    transition: max-height 0.3s ease-in-out;
    transition-delay: 0.25s;

    &:focus {
      max-height: 250px;
      transition-delay: 0s;
    }
  }

  @media screen and (-webkit-min-device-pixel-ratio:0) { 
    textarea,
    input {
      font-size: 16px;
    }
  }

  button {
    cursor: pointer;

    &:disabled {
      pointer-events: none;
      opacity: 0.5;
    }

    span {
      position: relative;
      display: inline-block;
      padding-bottom: 3px;

      &:after {
        @include pseudo;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        background: currentColor;

        transform: scaleX(0);
        transform-origin: 0% 50%;
      }
    }

    &:not(:disabled):hover {
      span:after {
        animation: underline 1s 1 ease-in-out forwards;
      }
    }

    @keyframes underline {
      0% { transform: scaleX(0); transform-origin: 0% 50%; }
      33% { transform: scaleX(1); transform-origin: 0% 50%; }
      33.3333% { transform: scaleX(1); transform-origin: 100% 50%; }
      66% { transform: scaleX(0); transform-origin: 100% 50%; }
      66.6666% { transform: scaleX(0); transform-origin: 0% 50%; }
      100% { transform: scaleX(1); transform-origin: 0% 50%; }
    }
  }

  &.disabled {
    pointer-events: none;

    label,
    input,
    textarea,
    button {
      opacity: 0.5;
    }
  }

  p {
    animation: fade-msg 0.3s 1 ease-in-out;
  }

  @keyframes fade-msg {
    0% { opacity: 0; transform: translateY(100%); }
    100% { opacity: 1; transform: translateY(0%); }
  }
}

.DarkMode .Form {
  .field:after {
    background: color(white);
  }
  .field.current {
    color: color(white);
  }
}