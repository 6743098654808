@import '../../static/variables';
@import '../../static/mixins';

.Home {
  @include forced_center(fixed);
  text-align: center;

  .logo-wrapper {
    perspective: 50em;
    transform-style: preserve-3d;
    transition: perspective 0.25s ease-in-out;
    @include animation(fade_logo, 0.75s, ease-in-out, 0, 1, normal, forwards);

    &:hover {
      perspective: 40em;
    }
  }

  @keyframes fade_logo {
    0% { opacity: 0; transform: translateY($spacing * 2); }
    100% { opacity: 1; transform: translateY(0); }
  }

  .logo {
    position: relative;
    display: block;

    color: color(black);
    transition: color 0.3s ease-in-out;

    text-align: center;
    font-family: 'Lavanderia';
    font-weight: bold;
    font-size: 7em;
    letter-spacing: .01em;
    line-height: 1.4;
    white-space: nowrap;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;

    user-select: none;

    text-shadow: -0.25vw -0.25vw 0.5vw color(black, 0.5);

    cursor: default;
    transform-style: preserve-3d;

    @include media-sm {
      font-size: 10em;
    }
    
    @media screen and (min-height: 600px) and (min-width: view(md)) {
      font-size: 15em;
    }
    
    @keyframes e_zoom {
      0% { transform: scale(1.15, 1.15) translateZ(0.1em); }
      100% { transform: scale(1, 1) translateZ(0.1em); }
    }

    b {
      position: relative;
      display: inline-block;
      letter-spacing: -0.03em;
      transform: translateZ(0.08em);
      text-shadow: -0.25vw -0.25vw 1vw color(black, 0.75);

      &:first-of-type {
        @include animation(e_zoom, 1s, ease-in-out, 0.2s, 1, normal, forwards);
        transform: scale(1.15, 1.15) translateZ(0.1em);
        left: 0.05em;
        font-size: 1.2em;
      }
    }

    span {
      position: absolute;
      left: 50%;
      bottom: 15px;
      margin-left: 60px;

      display: inline-block;
      vertical-align: middle;
      box-shadow: 0 0 1px color(black, 0);
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      -moz-osx-font-smoothing: grayscale;

      font-size: 0.3em;
      color: color(highlight);
      text-shadow: -1px -1px 0.2rem color(black, 0.75);
      transform: rotate(-15deg) translateZ(0.3em);
    
      @media screen and (min-height: 600px) and (min-width: view(md)) {
        margin-left: 160px;
        bottom: 40px;
        text-shadow: -2px -2px 0.35rem color(black, 0.75);
      }
    }
  }
}

.DarkMode .Home {
  .logo {
    color: color(white);
  }
}