@import '../../static/variables';
@import '../../static/mixins';

.Contact {
  overflow: hidden;

  @include media-md {
    .Form {
      padding-top: 4.8em;
    }
  }
}
