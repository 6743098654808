@import '../../static/variables';
@import '../../static/mixins';
@import '../../static/fonts';

.Works {
  overflow: hidden;

  .carousel {
    position: absolute;
    top: 50%;
    transform: translateY(calc(#{$spacing} - 50%));
    width: 100%;
    padding: 15vh 0;
    overflow-x: hidden;

    @include media-md {
      transform: translateY(-50%);
    }
  }

  .slide {
    position: relative;
    width: $project-thumbnail-width;
    max-width: $project-thumbnail-max-width;
    padding: 0 $project-thumbnail-side-padding;
  }

  .info {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(-150%, -50%);

    opacity: 0;
    transition: all 0.3s ease-in-out, font-size 0.2s ease-in-out;

    font-size: 1.8em;

    &.anim-out { font-size: $project-main-title-size }

    span {
      position: absolute;
      right: 0;
      top: 0;
      width: calc(100% - #{$project-thumbnail-side-padding});
      overflow: hidden;

      opacity: 1;
      transition: opacity 0.3s ease-in-out;
    }

    &.anim-out span {
      opacity: 0;
    }

    span h2 {
      white-space: nowrap;
      transform: translateX(-#{$project-thumbnail-side-padding});
      width: calc(100% + #{$project-thumbnail-side-padding});
      color: color(lightgrey);
      text-shadow: 1px 1px 1px color(black, 0.5);
    }
  }

  .is-selected .info.in {
    transform: translate(0%, -50%);
    opacity: 1;
  }
}

.DarkMode .Works .info span h2 {
  text-shadow: 1px 1px 1px color(black);
}

.Works .counter {
  position: absolute;
  right: $spacing;
  bottom: $spacing;

  display: flex;
  align-items: center;

  @include media-md {
    right: $spacing * 1.5;
  }
  @include media-lg {
    right: $spacing * 2;
  }

  .progress {
    position: relative;
    width: 50vw;
    max-width: 200px;
    height: 1px;
    background: color(grey, 0.5);
    margin-right: $spacing;

    transition: all 0.25s ease-in-out;
    transform: translateX(-50%);
    opacity: 0;

    > div {
      width: 100%;
      height: 100%;
      background: currentColor;
      opacity: 0.75;

      transform-origin: 0% 50%;
    }
  }

  &.in .progress {
    transform: translateX(0%);
    opacity: 1;
  }

  span {
    display: inline-block;
    transition: all 0.25s ease-in-out;
    transform: translateY(50%);
    opacity: 0;

    @include Bebas(300);
    font-size: 2em;
  }

  &.in span {
    transform: translateY(0%);
    opacity: 0.75;
  }
}