$view: (
  sm: 451px,
  md: 769px,
  lg: 1021px,
  xl: 1261px,
  hd: 1601px,
);

@function view($key) {
  @return map-get($view, $key);
}

$color: (
  black: #000,
  white: #fff,
  lightgrey: #dcdcdc,
  grey: #8a8a8a,
  darkgrey: #3e3e3e,
  highlight: #fb6542,
);

@function color($key, $alpha: 1) {
  @return rgba(map-get($color, $key), $alpha);
}

$container-width: 80%;
$container-offset: (100% - $container-width) / 2;
$container-max-width: view(xl);
$container-break-point: #{$container-max-width / $container-width * 100%};
$container-broken-offset: calc(50% - #{$container-max-width / 2});

$spacing: 25px;

$project-thumbnail-side-padding: 2vw;
$project-thumbnail-width: $container-width * 5 / 6;
$project-thumbnail-max-width: $container-max-width * 5 / 6;
$project-thumbnail-height: 70vh;
$project-main-title-size: 4em;
