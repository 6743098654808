@import '../../static/variables';
@import '../../static/mixins';

.Hamburger {
  outline: none;
  border: none;
  background: none;

  cursor: pointer;

  width: 2em;
  height: 14px;

  &:before,
  &:after {
    @include pseudo;

    left: 4px;

    width: 100%;
    height: 2px;

    background: currentColor;

    transform-origin: 100% 50%;
    transition: transform 0.3s ease-in-out;
  }

  &:before {
    top: 4px;

    transform: scale(0.75, 1);
  }

  &.hover:before {
    transform: scale(1, 1);
  }

  &:after {
    bottom: 0;
  }

  &.toggled {
    &:before {
      transform: scale(1, 1);
    }

    &:after {
      transform: scale(0.75, 1);
    }

    &.hover:after {
      transform: scale(1, 1);
    }
  }
}
