@import './static/variables';
@import './static/mixins';
@import './static/reset';
@import './static/grid';
@import './static/fonts';

@include fonts;

.container { @include container }

#root { overflow: hidden; height: 100%; }

h1 {
  margin: ($spacing / 2) 0 $spacing;
  font-family: 'Lavanderia';
  font-size: 3em;
}

h2 {
  @include Bebas();
  font-size: 1.4em;
}