* {
  border: 0;
  margin: 0;
  padding: 0;
  outline: none;
  background: none;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  box-sizing: border-box;

  -webkit-tap-highlight-color: color(white, 0);
  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  overflow-x: hidden;
}

html,
body {
  position: relative;
  width: 100%;
  height: 100%;
  background: color(white);
  color: color(darkgrey);
  font-family: 'Lora', serif;
  font-size: 12px;
  letter-spacing: .017em;
  line-height: 1;
  overflow: hidden;
  -webkit-text-size-adjust: 100%;

  @include media-md {
    font-size: 14px;
  }

  @include media-md {
    font-size: 14px;
  }

  @include media-lg {
    font-size: 15px;
  }

  @include media-xl {
    font-size: 16px;
  }
  
  ::-webkit-scrollbar { 
    display: none; 
  }
}

ul,
li {
  position: relative;
  display: block;
}

a,
a:visited,
a:active {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

p {
  position: relative;
  margin-bottom: 1.2em;

  font-size: 1em;
  line-height: 1.5;
  white-space: pre-wrap;

  a,
  a:visited {
    transition: all 0.3s ease-in-out;
    color: color(highlight);

    &:hover {
      color: inherit;
      text-decoration: underline;
    }
  }
}

b {
  font-weight: 700;
}

img {
  display: block;
  width: 100%;
  height: auto;
}

input,
button {
  display: inline-block;
  outline: none;
  border: none;
  border-radius: 0;
  background: none;
}
