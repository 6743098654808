@import '../../static/variables';
@import '../../static/mixins';
@import '../../static/fonts';

.Project {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  section {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .intro {
    font-size: $project-main-title-size;

    .thumbnail-wrap {
      position: absolute;
      left: 0;
      bottom: 15vh;
  
      width: calc(#{$project-thumbnail-width} - #{$project-thumbnail-side-padding * 2});
      max-width: calc(#{$project-thumbnail-max-width} - #{$project-thumbnail-side-padding * 2});
      height: $project-thumbnail-height;

      overflow: hidden;

      .thumbnail,
      .thumbnail > div {
        width: 100%;
        height: 100%;
      }

      .thumbnail > div {
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        transition: transform 0.6s ease-in-out;
      }
    }

    &:not(.active) .thumbnail > div {
      transform: translateY(50vh);
    }
  
    h2 {
      position: absolute;
      transform: translateY(50%);
      text-shadow: 1px 1px 1px color(white, 0.5);
    }
  }

  > h2 {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%) rotate(-90deg);
    transform-origin: 50% 0%;

    @include media-md {
      display: block;
      left: $spacing * 1.5;
    }
    @include media-lg {
      left: $spacing * 2;
    }

    transition: opacity 0.3s ease-in-out;

    &.shown { opacity: 0.75 }
    &.hidden { opacity: 0 }
  }

  .Link {
    position: absolute;
    right: 100%;
    top: 50%;
    transform: translate(-50%, -50%);

    .arrow {
      position: relative;
      width: 20px;
      height: 20px;
      transition: all 0.5s ease-in-out;

      &:before,
      &:after {
        @include pseudo;
        left: 0;
        top: 0;
      }
      
      &:before {
        width: 1px;
        height: 100%;
        background: color(highlight);
      }
      
      &:after {
        width: 0; 
        height: 0; 
        border-top: 6px solid transparent;
        border-left: 6px solid color(highlight);
      }
    }
  }

  .hidden .Link .arrow {
    opacity: 0;
    transform: translateY(100%);
  }

  .shown .Link .arrow {
    transition-delay: 0.8s;
  }

  .scroll-prompt {
    position: absolute;
    right: $container-offset;
    bottom: 0;

    .prompt {
      position: absolute;
      right: 100%;
      bottom: $spacing / 2;
      transform: translate(-100%, 0);
      opacity: 0;
      transition: all 0.3s ease-in-out;
      cursor: pointer;

      @include Bebas(300);
    }

    &.in .prompt {
      transform: translate(-50%, 0);
      opacity: 1;
    }

    .section-indicator {
      position: relative;
      width: 1px;
      height: 100px;
      background: color(grey, 0.5);
      cursor: pointer;
      
      > div {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: currentColor;
        transform-origin: 50% 0%;
        transition: transform 0.3s ease-in-out;
      }
    }
  }
}

.Project.is-mobile {
  position: relative;
  height: auto;

  section {
    position: relative;
    height: auto;
    transform: none;
  }

  .intro {
    font-size: 2em;

    .thumbnail-wrap {
      position: relative;
      bottom: 0;
  
      margin: auto;
      width: $container-width;
      height: 50vw;
    }

    &:not(.active) .thumbnail > div {
      transform: translateY(0);
    }
  
    h2 {
      position: relative;
      right: auto !important;
      bottom: auto !important;
      margin: $spacing 0 ($spacing * 1.5);
      padding: 0 $container-offset;
    }
  }

  > h2,
  .scroll-prompt {
    display: none;
  }
}

.DarkMode .Project .intro h2 {
  text-shadow: 1px 1px 1px color(black, 0.5);
}