@import '../../static/variables';
@import '../../static/mixins';

.Preloader {
  .load-bar {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 2px;
    background: color(black);
    transform-origin: 0% 50%;
  }

  .Loader {
    position:fixed;

    @include media-md {
      width: 60px;
      height: 65px;
    }
  }
}

.DarkMode .Preloader {
  .load-bar {
    background: color(white);
  }
}