.row {
  @include row();
  &.reversed { flex-direction: row-reverse }
}

@mixin create-grid($size) {
  .#{$size}-1 { width: 100% }
  .#{$size}-1-2 { width: 50% }
  .#{$size}-1-3 { width: 33.33334% }
  .#{$size}-2-3 { width: 66.66666% }
  .#{$size}-1-4 { width: 25% }
  .#{$size}-3-4 { width: 75% }
  .#{$size}-1-5 { width: 20% }
  .#{$size}-2-5 { width: 40% }
  .#{$size}-3-5 { width: 60% }
  .#{$size}-4-5 { width: 80% }
  .#{$size}-1-6 { width: 16.66667% }
  .#{$size}-5-6 { width: 83.33333% }
  .#{$size}-1-8 { width: 12.5% }
}

@include create-grid(xs);
@media screen and (min-width: view(sm)) { @include create-grid(sm) }
@media screen and (min-width: view(md)) { @include create-grid(md) }
@media screen and (min-width: view(lg)) { @include create-grid(lg) }
@media screen and (min-width: view(xl)) { @include create-grid(xl) }
@media screen and (min-width: view(hd)) { @include create-grid(hd) }

@for $i from 0 through 50 {
  @if $i % 5 == 0 {
    .row {
      &.space#{$i} {
        margin-left: (-$i + px);
        margin-right: (-$i + px);

        > * {
          padding-left: ($i + px);
          padding-right: ($i + px);
        }
      }
    }
  }
}